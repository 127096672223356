import axios from 'axios';

class StatisticService {
  async getTotalSearch(startTime, endTime, groupBy, userType='non-staff') {
    const response = await axios
      .get('api/statistics/total_search/',{ params: {
        timestamp_lte: startTime,
        timestamp_gte: endTime,
        group_by: groupBy,
        user_type: userType
      }});
    return response.data;
  }

  async getTotalCompare(startTime, endTime, groupBy, userType='non-staff') {
    const response = await axios
      .get('api/statistics/total_compare/',{ params: {
        timestamp_lte: startTime,
        timestamp_gte: endTime,
        group_by: groupBy,
        user_type: userType
      }});
    return response.data;
  }

  async getTotalProductEmailed(startTime, endTime, groupBy, userType='non-staff') {
    const response = await axios
      .get('api/statistics/total_product_emailed/',{ params: {
        timestamp_lte: startTime,
        timestamp_gte: endTime,
        group_by: groupBy,
        user_type: userType
      }});
    return response.data;
  }

  async getTotalEmailProductWorth(startTime, endTime, userType='non-staff') {
    const response = await axios
      .get('api/statistics/total_emailed_product_value/',{ params: {
        timestamp_lte: startTime,
        timestamp_gte: endTime,
        user_type: userType
      }});
    return response.data;
  }

  async getTotalEmailed(startTime, endTime, groupBy, userType='non-staff') {
    const response = await axios
      .get('api/statistics/total_emailed/',{ params: {
        timestamp_lte: startTime,
        timestamp_gte: endTime,
        group_by: groupBy,
        user_type: userType
      }});
    return response.data;
  }

}

export default new StatisticService();